import React, { useContext, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { P, PageElements } from '../../components/PageElements';
import { Steps } from '../../components/Steps';
import Button from '../../components/Button';
import FloatingButton from '../../components/FloatingButton';
import Image from 'next/image';

import { PeikkoContext } from '../../contexts/PeikkoContext';

const Image1 = () => {
  return (
    <Image
      src="/images/AdobeStock_341598299.webp"
      alt=""
      objectFit="cover"
      objectPosition="center"
      layout="fill"
      className="rounded-[6%] rounded-tr-[50%]"
    />
  );
};

const steps = [
  { id: '1', status: 'current' },
  { id: '2', status: 'upcoming' },
  { id: '3', status: 'upcoming' },
  { id: '4', status: 'upcoming' },
];

const Onboarding1 = (): ReactElement => {
  const { i18n } = useContext(PeikkoContext);
  const router = useRouter();

  const next = (): void => {
    router.push('/onboarding/2');
  };

  return (
    <>
      <PageElements>
        <div className="!mb-40 w-full">
          <P role="heading" aria-level={1}>
            <span className="">{i18n.t('onboarding1.body1')}</span>
            <span className="text-primary">{i18n.t('onboarding1.body2')}</span>
            <span className="">{i18n.t('onboarding1.body3')}</span>
          </P>
          <div className="relative w-full lg:w-3/4 aspect-square">
            <Image1 />
          </div>
        </div>
      </PageElements>
      <div className="fixed inset-x-0 bottom-0 h-32 md:h-40 bg-gradient-to-t from-white via-white to-transparent"></div>

      <FloatingButton>
        <Steps steps={steps} />
        <Button onClick={next}>{i18n.t('onboarding1.continueButton')}</Button>
      </FloatingButton>
    </>
  );
};

export default Onboarding1;
