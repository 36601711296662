const Step = ({ status }) => (
  <li className="flex-1">
    {status === 'complete' ? (
      <div className="flex flex-col border-t-8 rounded border-kkm-lightgreen"></div>
    ) : status === 'current' ? (
      <div
        className="flex flex-col border-t-8 rounded border-kkm-darkgreen"
        aria-current="step"
      ></div>
    ) : (
      <div className="flex flex-col border-t-8 border-gray-300 rounded "></div>
    )}
  </li>
);

export const StepsBase = ({ steps }) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex py-6 space-x-4 space-y-0">
        {steps.map((step) => (
          <Step {...step} key={step.id} />
        ))}
      </ol>
    </nav>
  );
};

export const Steps = (props) => (
  <div className="mx-8 md:mx-2">
    <StepsBase {...props} />
  </div>
);
